import { SiteInit } from './assets/siteinit';
import { SA } from './assets/scrollAnimation';
//import { CSSAcdn } from './assets/accordion';

/*
SiteInit option default
{
	menuBtn: '#menuBtn', //メニューボタンのセレクタ
	menu: '#menu', //メニューのセレクタ
	menuBtnSwitch: 'on', //メニューを開く時にメニューボタンに追加するクラス
	menuSwitch: 'show', //メニューを開く時にメニューに追加するクラス
	anchorIgnore: '.noscroll', //スムーススクロールを適用しない要素のセレクタ
	anchorSpeed: 300, //スムーススクロールのスピード
	anchorCallback: null, //スムーススクロール時に実行するコールバック関数
}
メソッド
siteInit.anchorScroll(href, cb); // hrefまでスムーススクロールさせる。hrefはセレクタorDOMオブジェクト cbはコールバック
siteInit.menuToggle() // メニューの開閉を切り替える（メニューボタンとメニューのクラスを切り替える）
siteInit.menuOpen() // メニューを開く（メニューボタンとメニューに開く時用のクラスをセットする）
siteInit.menuClose() // メニューを閉じる（メニューボタンとメニューの開く時用のクラスを削除する）
*/

const siteInit = new SiteInit({
	anchorCallback: function () {
		siteInit.menuClose(); //スムーススクロール時にメニューを閉じる処理
	}
});


/*
ScrollAnimation option default
{
	selector: '.anm', //スクロールアニメーションを適用する要素のセレクタ
	margin: window.innerHeight * 0.2, //スクロールアニメーションを適用する要素を表示させる位置（デフォルトは画面の一番下から20%の位置）
	showClass: 'show', //スクロールアニメーションを適用する要素が表示位置に来た時に追加するクラス
}
*/

const sa = new SA({
	margin: window.innerHeight * 0.1
});


const tabs = document.querySelectorAll('.js_tab');
const tabsSetClass = (target) => {
	const targetElms = document.querySelectorAll('[data-tab-target="' + target + '"]');
	targetElms.forEach((elm, i) => {
		const _tabs = elm.parentNode.querySelectorAll('.js_tab');
		_tabs.forEach((elm, i) => {
			elm.classList.remove('on');
		});
		elm.classList.add('on');
	});
};
const tabContentsSetClass = (target) => {
	const targetElm = document.querySelector(target);
	const currentContents = targetElm.parentNode.querySelectorAll('.js_tabContent.show');
	currentContents.forEach((elm, i) => {
		elm.classList.remove('show');
	});
	targetElm.classList.add('show');
};
tabs.forEach((elm, i) => {
	elm.addEventListener('click', (e) => {
		const target = elm.getAttribute('data-tab-target');
		const targetElm = document.querySelector(target);
		targetElm.classList.add('show');
		tabContentsSetClass(target);
		tabsSetClass(target);
		siteInit.anchorScroll(elm.getAttribute('data-tab-scrollTo'));
	}, false);
});
